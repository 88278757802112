const approve = [
  {
    id: 1,
    Plant: 'GC1',
    Pantry: 'Sup',
    เลขที่เอกสาร: 'PR 211100001',
    วันที่เอกสาร: '01/11/2021',
    ผู้สั่งสินค้า: 'U001',
    ผู้ตรวจสอบสั่งสินค้า: 'S001',
    วันที่ตรวจสอบสั่งสินค้า: '01/11/2021',
    สถานะ: 'ยืนยันคำสั่งสินค้า'
  },
  {
    id: 2,
    Plant: 'GC1',
    Pantry: 'Sup',
    เลขที่เอกสาร: 'PR 211100001',
    วันที่เอกสาร: '01/11/2021',
    ผู้สั่งสินค้า: 'U001',
    ผู้ตรวจสอบสั่งสินค้า: 'S001',
    วันที่ตรวจสอบสั่งสินค้า: '01/11/2021',
    สถานะ: 'ยืนยันคำสั่งสินค้า'
  },
  {
    id: 3,
    Plant: 'GC1',
    Pantry: 'Sup',
    เลขที่เอกสาร: 'PR 211100001',
    วันที่เอกสาร: '01/11/2021',
    ผู้สั่งสินค้า: 'U001',
    ผู้ตรวจสอบสั่งสินค้า: 'S001',
    วันที่ตรวจสอบสั่งสินค้า: '01/11/2021',
    สถานะ: 'ยืนยันคำสั่งสินค้า'
  }
];

export default approve;
